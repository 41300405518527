.home_container{
    position: relative !important;
    background-image: url("./Home3.jpg");
    background-position: center;
    background-size: contain;
    object-fit: cover;
    min-height: 700px;
    max-width: 100%;
}

@media (max-width:980px){
    .home_container{
        position: relative !important;
        background-image: url("./Home4.png");
        background-position: center;
        background-size: contain;
        object-fit: cover;
        min-height: 700px;
        max-width: 100%;
    }
}