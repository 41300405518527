.checkout-parent{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 5%;
    background: none !important;

}

.checkout-parent .video-parent {
    max-height: 400px;
    position: relative;
    background: transparent;
    margin: 0;
    box-shadow:  5px 5px 14px #d6d4d4,
                -15px 15px 60px #cfcfcf;
}

.checkout-parent .course-info{
    box-shadow:  15px 5px 14px #d8d8d8,
    -15px 5px 30px #e6e6e6 !important;
    border-radius: 5%;
    overflow-wrap: break-word;

    margin-top: 2%;

  }

.description{
    box-shadow:  5px 5px 14px #d6d4d4,
    -15px 15px 60px #cfcfcf;
}