.instructors-parent{
    background: url("./fa.jpg");
    background-size: cover;
    background-position: right;
    position: relative;
    margin-top: -1%;
}

@media (max-width:980px){
    .instructors-parent{
        background: url("../Courses/back.png");
        background-size: cover;
        background-position: right;
        position: relative;
    }
}
