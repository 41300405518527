


@media (max-width:980px){
    .contact-parent{
        background-image: url("../Courses/back.png");
    }
    .contact-parent form input,textarea{
        background: whitesmoke !important;
    }
}