.exam {
    border-radius: 10px;
}

.exam-container {
    margin: 2% auto;
    min-height: 600px;
    padding: 5%;
    color: white;
    border-radius: 34px;
    background: #413f3f;
    box-shadow: 28px 28px 56px #bebebe,
        -28px -28px 56px #ffffff;

}

.exam-container .row {
    justify-content: space-around;
    align-items: center !important;
}

.exam-parent,
.topics-parent {
    min-height: 400px;
}

.exam-container .topics-parent .accordion-body {
    padding-left: 0;
}

.exam-container .topics-parent .accordion-body li {
    padding: 3% 0;
    border-bottom: .5px solid #f9f9f9;
}


.exam-container .topics-parent .accordion-body li:not(.placeholder):not(.topic):not(.exam):not(:last-child)::before {
    content: "";
    position: absolute;
    top: 24px;
    left: 15px;
    width: 2px;
    height: 25px;
    background: rgb(0, 161, 73);
    overflow: hidden;
}

.exam-container .topics-parent .accordion-body li a {
    color: #c0ca2a;
}

.exam-container .topics-parent .accordion-body li a:hover {
    color: #9ba32e;
}

.exam-parent {
    position: relative;
}

.exam-container .topics-parent .accordion-body li {
    border-bottom: 1px solid rgb(230, 230, 230);
}






.exam-parent .introductions {
    padding: 3%;
    border-right: 1px solid #f9f9f9;
}

@media (max-width:920px) {
    .exam-parent .introductions {
        border-right: none;
    }

}

.exam-parent .introductions .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5%;
}

.exam-parent .introductions .top>* {
    width: 30%;
    height: 150px;
    background-color: #1E87F0;
    text-align: center;
    justify-content: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 1.1rem;
    border-radius: 5%;
    padding: 2%;
}



.exam-parent .exam .tools {
    display: flex;
    justify-content: space-around !important;
    padding: 5%;
    text-align: center;
    position: relative;
    border-bottom: 1px solid rgb(228, 228, 228);
}

.tools .progress {
    flex: 2;
    width: 80%;
    margin: 2% auto;
    position: absolute;
    bottom: 0%;
}

.exam-parent .exam .tools svg {
    cursor: pointer;
}

.exam-parent .exam .tools span {
    font-size: 20px;
}

.exam-parent .exam .question {
    padding: 2%;
    background-color: #dddcdc;
    width: 90%;
    margin: auto;
    font-size: 20px;
    font-weight: 800;
    border-radius: 10px;
}

.exam-parent .exam .answers form>* {
    width: 100%;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 10px;
    margin: 2% auto;
    position: relative;
    height: 40px;
    padding: 3%;
}

.exam-parent .exam .answers form input {
    position: absolute;
    top: 10%;
    left: 5%;
}

.exam-parent .exam .answers form label {
    position: absolute;
    top: 10%;
    left: 5%;
}

.exam-parent .exam .submition .top {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2%;
}

.exam-parent .exam .submition .bottom-right {
    width: 50%;
    margin: auto;
    display: flex;
    text-align: center;
    justify-content: space-around;
    margin-bottom: 5%;
}