
.page-section {
    position: relative;
    padding-top: 70px;
    margin-bottom: -5%;
    overflow: hidden;
    padding-bottom: 0;
  }

  .sidebar-block {
    position: relative;
    display: block;
    padding: 15px 24px;
    margin-bottom: 32px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #E8EEE4;
    box-shadow: 0 4px 12px rgba(154, 159, 151, 0.2);
    box-shadow:  5px 5px 14px #d6d4d4,
    -15px 15px 60px #cfcfcf;
  }
  
  .sidebar-block::after {
    display: block;
    clear: both;
    content: "";
  }
  
  .sidebar-title {
    font-weight: 600;
    margin-bottom: 24px;
    color: #596261;
  }
  
  .sidebar-title::after {
    content: '';
    display: block;
    margin-top: 10px;
    width: 50px;
    height: 3px;
    background-color:#AE33FD;
    border-radius: 40px;
  }
  
  .search-form .form-group {
    position: relative;
  }
  
  .search-form .btn {
    position: absolute;
    top: 5px;
    right: 6px;
    padding: 6px 12px;
    background-color:#AE33FD;
    color: #fff;
    transition: all .2s ease;
  }
  
  .search-form .btn:hover {
    background-color: #3a3638;
  }
  
  .search-input{
    width: 100%;
    outline: none;
    border: none;
  }

  .card-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 450px;
  }
.card-blog {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #E5E5E5;
    overflow: hidden;
    box-shadow:  5px 5px 14px #d6d4d4,
    -15px 15px 60px #cfcfcf;
  }
  
  .card-blog .header {
    width: 100%;
    height: 140px;
    background-color: #9A9F97;
    overflow: hidden;
  }
  
  .card-blog .header .post-thumb {
    position: relative;
    overflow: hidden;
  }
  
  .card-blog .header .post-thumb img {
    width: 100%;
    transition: transform .2s linear;
  }
  
  .card-blog .header .post-thumb:hover img {
    transform: scale(1.2);
  }
  
  .card-blog .body {
    padding: 15px 20px;
  }
  
  .card-blog .post-category {
    position: absolute;
    top: 15px;
    left: 20px;
    z-index: 9;
  }
  
  .card-blog .post-category span {
    display: inline-block;
    padding: 4px 8px;
    font-size: 12px;
    background-color: #4E5AFE;
    color: #fff;
    border-radius: 40px;
    cursor: context-menu;
  }
  

  .card-blog .post-category a:hover {
    text-decoration: none;
  }
  
  .card-blog .post-title,
  .card-blog .post-title a {
    color: #343531;
  }
  
  .card-blog .post-title:hover,
  .card-blog .post-title a:hover {
    text-decoration: none;
    color:#AE33FD;
  }
  
  .card-blog .site-info {
    margin-top: 12px;
    font-size: 12px;
    color: #6D7170;
  }
  
  .card-blog .avatar-img {
    width: 28px;
    height: 28px;
  }
  
  .blog-details {
    position: relative;
    display: block;
  }
  
  .blog-details .post-thumb {
    position: relative;
    display: block;
    margin-bottom: 32px;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(154, 159, 151, 0.3);
  }
  
  .blog-details .post-title {
    margin-bottom: 24px;
    font-weight: 400;
  }
  
  .blog-details .post-content {
    font-size: 18px;
    line-height: 1.7;
    color: #6E807A;
  }
  
  .blog-details .post-thumb img {
    width: 100%;
  }
  
  .blog-details .post-meta {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
  }
  
  .blog-details .post-meta a {
    color: #6E807A;
  }
  
  .blog-details .post-meta a:hover {
    color:#AE33FD;
    text-decoration: none;
  }
  
  .blog-details .post-meta .divider {
    padding-left: 8px;
    padding-right: 8px;
  }
  
  .blog-details .post-tags {
    display: block;
    padding: 16px 0;
  }
  
  .blog-details .post-tags a {
    display: inline-block;
    padding: 4px 12px;
    background-color: #596261;
    color: #fff;
    font-size: 14px;
    border-radius: 40px;
    transition: all .2s ease;
  }
  
  .blog-details .post-tags a:hover {
    text-decoration: none;
    background-color:rgb(77,92,211);
    color: #fff;
  }
    
  
.paginationBttns {
    margin-left: -1%;
    /* margin-top: 5%; */
    margin: 5% auto !important;
    width: 100%;
    height: 40px;
    padding-bottom: 5px;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .paginationBttns a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid rgb(0, 0, 0);
    color: black;
    cursor: pointer;
  }
  
  .paginationBttns a:hover {
    color:rgb(255, 255, 255);
    text-decoration: none;
    /* background-color: #00D9A5; */
  }
  .paginationActive{
    color: rgb(0, 0, 0);
  }
  .paginationActive a {
    color: rgb(0, 0, 0);
    background-color:rgb(255, 255, 255);
  }
  
  .paginationDisabled a {
    color: #ffffff;
    background-color: rgb(0, 0, 0);
    border: none;
  }
  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    display: none;
  }

  .courses-parent{
    background-image: url("./f.jpg");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 100%;
    min-height: 600px;
  }
  @media (max-width:980px){
    .courses-parent{
        position: relative !important;
        background-image: url("./back.png");
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        height: 100%;
        min-height: 600px;
    }
}