* {
  font-family: 'Roboto Slab', serif;
}

body{

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE/Edge */
    user-select: none;

}

ul {
  list-style: none;
}

.active:not(.tab-pane):not(input) {
  color: #090C29;
  background-color: #6D6D75;
}
/* .navbar{
  background-color: none !important;
} */

.checkout-class {
  display: flex;
  flex-direction: row !important;
}

.scale:hover {
  transition: .5s ease-in-out all;
  transform: scale(1.2);
  transform: translateX(20px);
}

.navbar-hide {
  display: none;
}

.about-container {
  -moz-box-shadow: 10px 10px 14px -1px rgb(187, 187, 187);
  box-shadow: 10px 10px 104px -1px rgb(179, 178, 178);
}

.home_container {
  overflow: hidden;
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: cover !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  top: 0px;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .home_container .home-info{
  position: absolute;
  top: 35%;
  text-align: center;
} */

.home_container .home-info {
  padding-top: 5%;
  text-align: center;
}

.home_container .home-info .title {
  color: whitesmoke;
  /* color: #03CAD6; */

}

.home_container .home-info .prag {
  font-size: 1.2rem;
  color: #b3b3b3;
}

.home_container .home-info .get-more {
  font-size: 1.5rem;
  color: rgb(204, 202, 202);
  text-align: center;
  cursor: pointer;
  transition: .5s all ease-in-out;
  padding-left: 15% !important;
  width: auto;
}

.home_container .home-info .get-more:hover {
  transform: translateX(10px);
  color: rgb(192, 182, 197);

}


@media (max-width:991px) {
  .navbar {
    position: relative;
    background-color: rgb(0, 0, 0);
  }

  .navbar-collapse {
    position: relative;
    
  }
  .navbar-collapse li{
    color: white;
    
  }
  .container-fluid span {
    padding: 2%;
    color: whitesmoke !important;
  }

  svg {
    display: none !important;
  }

  .navbar i {
    color: rgb(255, 255, 255) 
  }

  .navbar li a {
    color: rgb(255, 255, 255) !important;
  }


  .navbar li a:hover {
    color: rgb(126, 126, 126) !important;
  }

}

.card {
  padding: 0 !important;
}

.card img {
  transition: .5s ease-in-out all;
  width: 100%;
  padding: 0;
  margin: 0;
}



.lectuer-name-vidorpdf:hover {
  color: black !important;
}

.comments img {
  width: 60px;
}

a.active {
  background-color: rgb(102, 10, 155) !important;
}

h6.alert {
  background-color: rgb(46, 69, 109) !important;
  color: whitesmoke;
  font-size: .9rem;
}

input {
  border-bottom: .2px solid rgb(207, 207, 207) !important;
}

.card {
  background: #fcfcfc;
  box-shadow: 15px 5px 14px #d8d8d8,
    -15px 5px 30px #e6e6e6 !important;
}
.card-instructors{
  background: #fcfcfc;
  padding: 5%;
}


td {
  width: auto !important;
  height: 1px !important;
  padding: 1% !important;
}

@media (max-width:720px) {
  tr {
    font-size: .8rem;
  }

  tbody {
    max-height: 100px !important;
    width: 100%;
    overflow: hidden;
  }

  td {
    width: auto !important;
    height: 1px !important;
    padding: 0% 1% !important;
    font-size: .7rem;
  }

  td:last-of-type {
    min-width: 210px;
  }
}

.review {
  display: flex;
  flex-wrap: wrap;
}

.checkout-form,
.video-container {
  max-width: 70%;
}

.checkout-form,
.video-container video {

  border-radius: 47px;
  background: #fcfcfc;
  box-shadow: 5px 5px 14px #e7e6e6,
    -15px 15px 60px #cfcfcf;
}


.checkout-form input {
  background: #f5f5f5 !important;
}






.pagenotfound-container {
  background: url(../Components/404/1847.jpg);
  background-position: center;
  height: 100vh;
  object-fit: contain;
  background-size: cover;
}

.pagenotfound-container .btn {
  position: absolute;
  top: 75%;
  left: 25%
}

@media (max-width: 768px) {
  .login-page {
    height: auto !important;
  }

  .user-add-comment {
    display: none;
  }

  .pagenotfound-container {
    background: url(../Components/404/for-mobile.jpg);
    background-position: center;
    height: 100vh;
    object-fit: contain;
    background-size: cover;
  }

  .pagenotfound-container .btn {
    position: absolute;
    top: 75%;
    left: 30%
  }
}




.correct {
  background-color: rgb(49, 153, 24);
}

.incorrect {
  background-color: rgb(204, 13, 29);
}




.table,.result-image,.search-result{
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
}

select,option{
  cursor: pointer;
}

.swiper-slide{
  display: flex;
  justify-content: center;
}



/* Firefox */
.table-parent {
  scrollbar-width: thin;
  scrollbar-color: #6D6D75 #0c0c14;
  margin-bottom: 5%;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
}

/* Chrome, Edge and Safari */
.table-parent::-webkit-scrollbar {
  width: 10px;
  width: 10px;
}
.table-parent::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #0f0e1f;
}

.table-parent::-webkit-scrollbar-track:hover {
  background-color: #B8C0C2;
}

.table-parent::-webkit-scrollbar-track:active {
  background-color: #B8C0C2;
}

.table-parent::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #6D6D75;
}

.table-parent::-webkit-scrollbar-thumb:hover {
  background-color: #090C29;
}

.table-parent::-webkit-scrollbar-thumb:active {
  background-color: #090C29;
}